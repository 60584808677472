<template>
  <div class="reservation-list">
    <div class="list" v-if="reservationObjects.length > 0">
      <div class="header-area">
        <ul class="reservation-list-header">
          <li class="reservation-list-header-column patient-name">
            {{ $t('common.labelPetName') }}
          </li>
          <li class="reservation-list-header-column purpose">
            {{ $t('common.labelPurposeOfVisit') }}
          </li>
          <li class="reservation-list-header-column date">
            {{ $t('common.reservationDate') }}
          </li>
          <li
            class="reservation-list-header-column button-area"
            v-if="reservationSetting.netAvailableFlg"
          ></li>
        </ul>
      </div>
      <div class="body-area">
        <reservation-list-item
          v-for="reservationObject in reservationObjects"
          :key="reservationObject.keyId"
          :reservation="reservationObject"
          :netAvailableFlg="reservationSetting.netAvailableFlg"
          :cancelableFlg="reservationSetting.cancelableFlg"
          :reservationChangeCancelAvailablePeriod="
            reservationSetting.reservationChangeCancelAvailablePeriod
          "
          @change-click="clickChange(reservationObject)"
          @cancel-click="clickCancel(reservationObject)"
        />
      </div>
    </div>
    <span class="message" v-else>{{ $t('parts.noReservations') }}</span>
  </div>
</template>

<script>
import ReservationListItem from '@/components/parts/molecules/ReservationListItem'
import _ from 'lodash'

export default {
  name: 'ReservationList',

  components: { ReservationListItem },

  props: {
    reservationPurposes: { type: Array },
    reservations: { type: Array },
    patients: { type: Array }
  },

  data() {
    return {
      reservationSetting: this.$store.getters[
        'reservationSetting/getReservationSetting'
      ]
    }
  },

  computed: {
    trimReservations() {
      const reservations = this.reservations
      const patients = this.patients
      const reservationPurposes = this.reservationPurposes
      const trimReservations = reservations.map(reservation => {
        const targetPatient = patients.find(
          patient => patient.id === reservation.patientId
        )
        const targetReservationPurpose = reservationPurposes.find(
          reservationPurpose =>
            reservationPurpose.id === reservation.reservationPurposeId
        )
        return {
          id: reservation.id,
          patientId: reservation.patientId,
          patientName:
            targetPatient !== undefined ? `${targetPatient.name}様` : '',
          patientSpeciesId:
            targetPatient !== undefined ? targetPatient.speciesId : '',
          reservationPurposeId: reservation.reservationPurposeId,
          reservationPurposeName:
            targetReservationPurpose !== undefined
              ? targetReservationPurpose.name
              : '',
          reservationPurposeNameEnglish:
            targetReservationPurpose !== undefined
              ? targetReservationPurpose.nameEnglish
              : '',
          date: reservation.date,
          startTime: reservation.startTime,
          groupKey: reservation.groupKey,
          requestFlg: reservation.reservationId === 0 ? true : false,
          medicalRecordOriginalId: reservation.medicalRecordOriginalId
        }
      })
      return trimReservations
    },
    reservationsJoinedSameGroupKey() {
      const reservationsHaveGroupKey = this.trimReservations.filter(
        v => v.groupKey !== ''
      )
      const reservationsGroupedByGroupKey = _.groupBy(
        reservationsHaveGroupKey,
        'groupKey'
      )
      let reservationsJoinedSameGroupKey = []
      for (const reservationsSameGroupKey of Object.values(
        reservationsGroupedByGroupKey
      )) {
        const reservationAssociatedWithKarte = reservationsSameGroupKey.find(
          v => v.medicalRecordOriginalId !== 0
        )
        reservationsJoinedSameGroupKey.push({
          ids: reservationsSameGroupKey.map(rsgk => rsgk.id),
          patientsIds: reservationsSameGroupKey
            .map(rsgk => rsgk.patientId)
            .filter(patientId => patientId > 0),
          patientsNames: reservationsSameGroupKey.map(rsgk => rsgk.patientName),
          patientSpeciesIds: reservationsSameGroupKey.map(
            rsgk => rsgk.patientSpeciesId
          ),
          reservationPurposeId:
            reservationsSameGroupKey[0].reservationPurposeId,
          reservationPurposeName:
            reservationsSameGroupKey[0].reservationPurposeName,
          reservationPurposeNameEnglish:
            reservationsSameGroupKey[0].reservationPurposeNameEnglish,
          date: reservationsSameGroupKey[0].date,
          startTime: reservationsSameGroupKey[0].startTime,
          requestFlg: reservationsSameGroupKey[0].requestFlg,
          medicalRecordOriginalId: reservationAssociatedWithKarte
            ? reservationAssociatedWithKarte.medicalRecordOriginalId
            : 0
        })
      }
      return reservationsJoinedSameGroupKey
    },
    reservationsNotHaveGroupKey() {
      const trimReservations = this.trimReservations
      const targetReservations = trimReservations.filter(v => v.groupKey === '')
      const reservationsNotHaveGroupKey = targetReservations.map(v => {
        return {
          ids: [v.id],
          patientsIds: v.patientId === 0 ? [] : [v.patientId],
          patientsNames: [v.patientName],
          patientSpeciesIds: [v.patientSpeciesId],
          reservationPurposeId: v.reservationPurposeId,
          reservationPurposeName: v.reservationPurposeName,
          reservationPurposeNameEnglish: v.reservationPurposeNameEnglish,
          date: v.date,
          startTime: v.startTime,
          requestFlg: v.requestFlg,
          medicalRecordOriginalId: v.medicalRecordOriginalId
        }
      })
      return reservationsNotHaveGroupKey
    },
    reservationObjects() {
      const reservationsJoinedSameGroupKey = this.reservationsJoinedSameGroupKey
      const reservationsNotHaveGroupKey = this.reservationsNotHaveGroupKey
      const reservationObjects = reservationsJoinedSameGroupKey
        .concat(reservationsNotHaveGroupKey)
        .map((v, i) => {
          v.keyId = i + 1
          return v
        })
      reservationObjects.sort((a, b) => {
        return a.date === b.date ? a.startTime - b.startTime : a.date - b.date
      })
      return reservationObjects
    }
  },

  methods: {
    clickChange(reservation) {
      this.$emit('change-click', reservation)
    },
    clickCancel(reservation) {
      this.$emit('cancel-click', reservation)
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-list {
  > .list {
    > .header-area {
      > .reservation-list-header {
        display: table;
        margin: 0 auto;
        padding: 0;
        border-collapse: collapse;
        box-sizing: border-box;
        margin-bottom: -2px;
        width: 960px;
        > .reservation-list-header-column {
          display: table-cell;
          vertical-align: middle;
          height: 45px;
          background-color: #{$plus_7c_gray};
          border: 2px solid #{$plus_7c_gray};
          box-sizing: border-box;
          font-weight: bold;
          color: #ffffff;
          &.patient-name {
            width: 16%;
          }
          &.purpose {
            width: 17%;
          }
          &.date {
            width: 42%;
          }
          &.button-area {
            width: 25%;
          }
        }
      }
    }
    > .body-area {
      > .reservation-list-item {
        margin-bottom: -2px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  > .message {
    margin-top: 20px;
    font-weight: bold;
    border-bottom: solid;
    border-color: #{$plus_light_orange};
    border-width: 5px;
  }
}
@media (max-width: 960px) {
  .reservation-list .list {
    > .header-area {
      > .reservation-list-header {
        width: 640px;
      }
    }
  }
}
@media (max-width: 640px) {
  .reservation-list .list {
    > .header-area {
      @include selector-width('.reservation-list-header', 620px, 640px);
      > .reservation-list-header {
        > .reservation-list-header-column {
          font-size: calc(100vw / 36);
          &.patient-name {
            width: 17%;
          }
          &.purpose {
            width: 17%;
          }
          &.date {
            width: 37%;
          }
          &.button-area {
            width: 29%;
          }
        }
      }
    }
  }
}
</style>
